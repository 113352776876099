import moment from "moment";
import { CDN_PATH } from "../config/config";
import {
  AssignedPlayerEvaluationFragment,
  AssignedPlayerFragment,
  EligibilityType,
  EvalTypeEnum,
  PlayerEvalFragment,
  PlayerEvalSlimFragment,
  PlayerFragment,
  PlayerPositionFragment,
  PlayerStatus,
  PositionFragment,
  ReportEvaluationFragment,
  UserFragment,
} from "../graphql/generated/graphql";
import _ from "lodash";
import { PermissionEnum, hasPermission } from "./perms";
import { colors } from "@mui/material";
import { ProReportPlayer, CollegeReportPlayer } from "../components/reporting/shared/reports";

export function getPlayerHeadshotUrl(playerId: string) {
  return `${CDN_PATH}/headshots/${playerId}.png`;
}

export enum PlayerOverrideEnum {
  School = "School",
  Club = "Club",
  Eligibility = "Eligibility",
  EligibilityYear = "EligibilityYear",
  HighSchoolGradYear = "HighSchoolGradYear"
}

export enum PlayerTagEnum {
  TransferPortal = "Transfer Portal",
  CutdownPriority = "Cutdown Priority",
  TradeCandidate = "Trade Candidate",
  ShortList = "Short List",
}

export function getPlayerDraftInfo(
  draftYear?: number | undefined | null,
  draftRound?: number | undefined | null,
  draftSelection?: number | undefined | null,
  draftClubName?: string
) {
  let draftLine = "";

  if (draftYear) {
    draftLine = `${draftYear}: `;
    if (draftClubName) {
      if (draftRound && draftSelection) {
        draftLine += `Rd ${draftRound}, Pk ${draftSelection}`;
      }
      draftLine += ` (${draftClubName})`;
    } else {
      draftLine = `${draftYear}`;
    }
  }
  return draftLine;
}

export function getPlayerHeight(height: number | undefined | null) {
  if (height) {
    if (`${height}`.length == 3) return height * 10;
  }
  return "";
}

export function getPlayerJerseyDisplay(jersey: string | undefined | null) {
  if (jersey) {
    if (jersey.length == 1 && jersey == "0") return "#0";
    if (jersey.length == 1) return `#0${jersey}`;
    return `#${jersey}`;
  }
  return "#00";
}

export function getPlayerAge(birthDate: string | undefined | null) {
  if (birthDate) {
    return moment().diff(birthDate, "years");
  }
}

export function getPlayerStatus(status: string) {
  switch (status) {
    case PlayerStatus.ACTIVE:
      return "Active";
    case PlayerStatus.ACTIVE_NON_FOOTBALL_INJURY:
      return "Active: NFI";
    case PlayerStatus.ACTIVE_PHYSICALLY_UNABLE_TO_PERFORM:
      return "Active: PUP";
    case PlayerStatus.INACTIVE:
      return "Inactive";
    case PlayerStatus.INJURED_RESERVE:
      return "IR";
    case PlayerStatus.INJURED_RESERVE_DESIGNATED_FOR_RETURN:
      return "IR DFR";
    case PlayerStatus.PHYSICALLY_UNABLE_TO_PERFORM:
      return "PUP";
    case PlayerStatus.PRACTICE_SQUAD:
      return "P-Squad";
    case PlayerStatus.PRACTICE_SQUAD_INJURED:
      return "P-Squad: Injured";
      case PlayerStatus.FREE_AGENT:
        return "Free Agent";
    default:
      return status;
  }
}

export const onInjuredReserve = (status?: PlayerStatus) => {
  if (!status) return false;
  if (status === PlayerStatus.INJURED_RESERVE) return true;
  if (status === PlayerStatus.NON_FOOTBALL_RELATED_INJURED_RESERVE) return true;
  if (status === PlayerStatus.INJURED_RESERVE_DESIGNATED_FOR_RETURN)
    return true;
  return false;
};

export const onPracticeSquad = (status: PlayerStatus) => {
  if (status === PlayerStatus.PRACTICE_SQUAD) return true;
  if (status === PlayerStatus.PRACTICE_SQUAD_COVID) return true;
  if (status === PlayerStatus.PRACTICE_SQUAD_INJURED) return true;
  if (status === PlayerStatus.PRACTICE_SQUAD_INTERNATIONAL) return true;
  if (status === PlayerStatus.PRACTICE_SQUAD_SUSPENDED) return true;
  return false;
};

export const isActive = (status: PlayerStatus) => {
  if (status === PlayerStatus.ACTIVE) return true;
  if (status === PlayerStatus.ACTIVE_NON_FOOTBALL_INJURY) return true;
  if (status === PlayerStatus.ACTIVE_PHYSICALLY_UNABLE_TO_PERFORM) return true;
  return false;
};

export function getEligiblitySort(elig: string) {
  if (elig == EligibilityType.SR) return 1;
  if (elig == EligibilityType.JR) return 2;
  if (elig == EligibilityType.SO) return 3;
  if (elig == EligibilityType.FR) return 4;
}

export function getPlayerDepth(playerPositions: PlayerPositionFragment[]) {
  if (playerPositions.length > 0) {
    return playerPositions[0].depth;
  }
  return 99;
}

export function getPlayerPosition(
  position: PositionFragment | undefined | null,
  playerPositions?: PlayerPositionFragment[]
) {
  if (position) return position.shortName;
  if (playerPositions && playerPositions.length > 0) {
    return playerPositions[0].position.shortName;
  }
  return "";
}

export const getDistinctAlerts = (
  player: PlayerFragment | undefined,
  asStringList?: boolean
) => {
  if (player) {
    if (player.evaluations && player.evaluations.length == 0) return "";

    const evalAlerts = player.evaluations?.filter(
      (e) => e.alerts && e.alerts.length > 0
    ) as typeof player.evaluations;

    return getAlerts(evalAlerts, asStringList);
  }
};

export const getMyAlerts = (
  player: PlayerFragment | undefined,
  userId: string | undefined,
  asStringList?: boolean
) => {
  if (player) {
    if (player.evaluations.length == 0) return "";

    const evalAlerts = player.evaluations.filter(
      (e) => e.alerts && e.alerts.length > 0 && e.userId == userId
    ) as typeof player.evaluations;
    
    return getAlerts(evalAlerts, asStringList);
  }
};

function getAlerts(
  evalAlerts: PlayerEvalFragment[] | undefined,
  asStringList?: boolean
) {
  const alerts = _.orderBy(
    evalAlerts?.flatMap((a) => a.alerts.flatMap((a) => a.alert)),
    ["sort"]
  );
  const seen = new Set<string>();
  const uniqueAlerts = alerts?.filter((a) => {
    if (seen.has(a.abbreviation)) return false;
    seen.add(a.abbreviation);
    return true;
  });

  if (asStringList) return uniqueAlerts?.map((a) => a.abbreviation).join(",");

  return uniqueAlerts;
}
export const getDistinctFits = (
  player: PlayerFragment | undefined,
  asStringList?: boolean
) => {
  if (player) {
    if (player.evaluations && player.evaluations.length == 0) return "";

    const evalFits = player.evaluations?.filter(
      (e) => e.fits && e.fits.length > 0
    );

    return getFits(evalFits, asStringList);
  }
};
export const getMyFits = (
  player: PlayerFragment | undefined,
  userId: string | undefined,
  asStringList?: boolean
) => {
  if (player) {
    if (player.evaluations.length == 0) return "";

    const evalFits = player.evaluations.filter(
      (e) => e.fits && e.fits.length > 0 && e.userId == userId
    );
    return getFits(evalFits, asStringList);
  }
};
function getFits(
  playerEvals: PlayerEvalFragment[] | undefined,
  asStringList?: boolean
) {
  if (!playerEvals) return "";

  const orderedEvals = _.orderBy(
    playerEvals,
    ["season", "lastUpdated"],
    ["desc", "desc"]
  ) as typeof playerEvals;

  const evalFits = orderedEvals?.flatMap((a) => a.fits);

  const orderedFits = _.orderBy(evalFits, ["priority"], ["asc"]);
  const seen = new Set<string>();
  const uniqueFits = orderedFits?.filter((a) => {
    if (seen.has(a.fit.name)) return false;
    seen.add(a.fit.name);
    return true;
  });

  if (asStringList) return uniqueFits?.map((a) => a.fit.name).join(", ");

  return uniqueFits;
}

export function getCleanHighSchool(school: string) {
  return school.replace("High School", "");
}

export function getHomeTown(
  city?: string | null,
  state?: string | null,
  country?: string | null
) {
  if (!city && !state && !country) {
    return "N/A";
  }

  if (city == "Unknown") return "N/A";
  const isInUSA = ["US", "USA"].includes(country || "");
  return isInUSA
    ? `${city}, ${state}`
    : `${city}, ${state || "-"}, ${country || "-"}`;
}

export function isPlayerCollege(draftYear: number | undefined | null) {
  if (draftYear === null || draftYear === undefined) {
    return true;
  }
  return false;
}

export const getSumerScoutGradeEval = (
  evaluations: PlayerEvalSlimFragment[] | undefined,
  user: UserFragment | null | undefined
) => {
  // only use the latest scout evals, exclude cross checks for now
  const orderedEvals = getMostRecentScoutEval(evaluations)

  const canReadAll = hasPermission(
    user?.role?.permissions,
    PermissionEnum.PermissionEvalAllRead
  );

  const scoutEval = canReadAll
    ? orderedEvals[0]
    : orderedEvals?.find((e) => e.userId == user?.id);

  return scoutEval;
};
export const getMostRecentScoutEval = (
    evaluations: PlayerEvalSlimFragment[] | undefined) =>{
      return _.orderBy(
        evaluations?.filter(
          (e) =>
            e.evalType.type === EvalTypeEnum.COLLEGE_SCOUT ||
            e.evalType.type == EvalTypeEnum.PRO_SCOUT
        ),
        ["season", "lastUpdated"],
        ["desc", "desc"]
      );
}

// TODO: refactor all of these
export const getDistinctAlertsRoster = (
  evaluations: PlayerEvalSlimFragment[] | undefined,
  asStringList?: boolean
) => {
  if (evaluations) {
    if (evaluations.length == 0) return "";

    const evalAlerts = evaluations.filter(
      (e) => e.alerts && e.alerts.length > 0
    );
    return getAlertsRoster(evalAlerts, asStringList);
  }
};

function getAlertsRoster(
  evalAlerts: PlayerEvalSlimFragment[] | undefined,
  asStringList?: boolean
) {
  const alerts = evalAlerts?.flatMap((a) => a.alerts.flatMap((a) => a.alert));
  const seen = new Set<string>();
  const uniqueAlerts = alerts?.filter((a) => {
    if (seen.has(a.abbreviation)) return false;
    seen.add(a.abbreviation);
    return true;
  });

  if (asStringList) return uniqueAlerts?.map((a) => a.abbreviation).join(",");

  return uniqueAlerts;
}
export const getMyAlertsRoster = (
  evaluations: PlayerEvalSlimFragment[] | undefined,
  userId: string | undefined,
  asStringList?: boolean
) => {
  if (evaluations) {
    if (evaluations.length == 0) return "";

    const evalAlerts = evaluations.filter(
      (e) => e.alerts && e.alerts.length > 0 && e.userId == userId
    );
    return getAlertsRoster(evalAlerts, asStringList);
  }
};
const getDecimalRoundedFromEighths = (fraction: number) => {
  //get decimal from eigths
  return Math.round(fraction / 8);
};
const getHeightInInches = (value: number | undefined) => {
  if (value == undefined || value == 0) return 0;
  const feet = String(value).substring(0, 1); // first number is feet
  const inches = String(value).substring(1, 3); // next two are inches
  const eighthsDecimal = getDecimalRoundedFromEighths(
    Number(String(value).substring(3, 4))
  ); // eighths

  const feet_inches = Number(feet) * 12;
  return feet_inches + Number(inches) + eighthsDecimal;
};

export const getWingSpanDifferential = (
  wing: number | string | undefined,
  height: number | string | undefined
) => {
  if (
    wing == undefined ||
    typeof wing === "string" ||
    height == undefined ||
    typeof height === "string"
  )
    return "--";

  const heightInches = getHeightInInches(height);
  const wingInches = wing;

  const diff = Math.round(wingInches - heightInches);
  return diff > 0 ? String("+" + diff) : String(diff);
};

export const getFreeAgencyStatusBackColor = (freeAgencyStatus: string) => {
  switch (freeAgencyStatus) {
    case "UFA":
      return "#F04438";
    case "RFA":
      return "royalblue";
    case "ERFA":
      return "yellow";
    case "CAP_CASUALTY":
      return "darkorange";
    default:
      "transparent";
  }
};
export const getFreeAgencyStatuForeColor = (freeAgencyStatus: string) => {
  switch (freeAgencyStatus) {
    case "ERFA":
      return `${colors.grey[800]}`;
    default:
      return `${colors.grey[50]}`;
  }
};
export const getVestedVet = (totalPensionCredits: number) => {
  if(totalPensionCredits >= 4) return "Y";
  return "N"
}

export const getPlayerFirstName = (footballName: string | undefined | null, firstName: string) => {
  if(footballName && footballName.trim() != "") return footballName;
  return firstName;
}